import cookie from 'cookie';
import { IncomingHttpHeaders } from 'http';
import Cookies from 'js-cookie';

import { Locale } from '../types/localization';
import { getFinnSessionId } from './session';

export const DEFAULT_COOKIE_EXPIRATION_DAYS = 1;

export enum CookieKeys {
  PAGE_NOT_AVAILABLE = 'page_not_available',
  VEHICLE_CART_NOT_AVAILABLE = 'vehicle_cart_not_available',
  DEAL_CLOSED = 'deal_closed',
  RIBBON_DISMISSED = 'ribbon_dismissed',
  CHECKOUT_TERM_NOT_AVAILABLE = 'checkout_term_not_available',
  CHECKOUT_PRICE_UPDATED = 'checkout_price_updated',
  IS_FOR_BUSINESS = 'is_for_business',
  CHECKOUT_DEAL_INFO = 'checkout_deal_info',
  FINN_AUTH = 'finn_auth',
  AUTH_EMAIL_VERIFIED = 'auth_email_verified',
  REFERRAL_VOUCHER_CODE = 'referral_voucher_code',
  VOUCHER_CODE = 'voucherCode',
  B2B_MULTIPLIER = 'b2b_multiplier',
  B2B_REFERRER_CODE = 'referrer-code',
  B2B_EMAIL_ADDRESS = 'b2b_email_address',
  UTM_SOURCE = 'b2b_utm_source',
  IS_MOBILE_FILTER_OPEN = 'is_mobile_filter_open',
  IS_REFERRAL_VOUCHER_TRACKED = 'is_referral_voucher_tracked',
  INVALID_PAYMENT_METHOD = 'invalid_payment_method',
  NEXT_LOCALE = 'NEXT_LOCALE',
  NETLIFY_LOCALE = 'nf_lang',
  E2E_TEST_IN_PROGRESS = 'E2E-test-in-progress',
  E2E_TEST_DL_VERIFIED = 'E2E-test-dl-verified',
  E2E_TEST_ID_VERIFIED = 'E2E-test-id-verified',
  E2E_TEST_EMAIL_VERIFIED = 'E2E-test-email-verified',
  BLACKLISTED = 'blacklisted',
  DISABLE_DIRECT_CHECKOUT_FOR_E2E = 'E2E-disable-direct-checkout',
  DEV_PASS = 'dev-pass',
  COSMIC_HELPER = 'cosmic-helper',
  CHECKOUT_HELPER = 'checkout-helper',
  LAST_CHECKOUT_STEP = 'last-checkout-step',
  X_FINN_SESSION_ID = 'X-Finn-Session-Id',
  STRIPE_ERROR_CODE = 'Stripe-Error-Code',
  HUBSPOT_UTK = 'hubspotutk',
  FULLSTORY_DE = 'de-DE_fullstory',
  FULLSTORY_US = 'en-US_fullstory',
  GCLID = '_gcl_aw',
  AWIN_CLID = '_awc',
  TISOOMI_CLID = '_tisoomi',
  REDDIT_CLID = '_rdt_cid',
  LINKEDIN_CLID = '_li_fat_id',
  DC_TRIGGER_DEAL_EVENT = 'DC_Deal',
  DC_CREDIT_SCORE = 'DC_credit_score',
  TEMP_DELIVERY_DATE = 'temp_delivery_date',
  TEMP_SWAP_DEAL_ID = 'temp_swap_deal_id',
  DELIVERY_MODAL_SHOWED = 'deliveryModalShowed',
  CLOSED_DEAL_ID = 'closedDealId',
  LAST_DEAL_INFO = 'lastDealInfo',
  LAST_DEAL_ID = 'lastDealId',
  RETURN_TO_CKO = 'returnToCKO',
  REMINDER_DISMISSED = 'reminderDismissed',
  HUBSPOT_ID = 'finn_hid',
  E2E_SECRET = 'e2e-secret',
  LOYALTY_INTRO_MODAL = 'finn_l_im',
  LAST_URL = 'finn_lu',
  SESSION_DATA = 'finn_sd',
  CLOSED_USER_GROUP_ID = 'cug_id',
  PICKUP_POINT_SUBMITTED = 'finn_pps',
}

export const setLocaleCookie = (locale: Locale) => {
  Cookies.set(CookieKeys.NEXT_LOCALE, locale); // https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
  Cookies.set(CookieKeys.NETLIFY_LOCALE, locale); // netlify uses different cookie name for locale
};

export const setClientCookie = (
  key: CookieKeys,
  value: string | object,
  expires: number = DEFAULT_COOKIE_EXPIRATION_DAYS
) => {
  Cookies.set(key, value, {
    expires,
  });
};

export const getClientCookie = (key: CookieKeys) => {
  return Cookies.get(key);
};

export const isCookieExist = (key: CookieKeys) => {
  return Boolean(Cookies.get(key));
};

export const setClientJSONCookie = (
  key: CookieKeys,
  jsonData: any,
  expires: number = DEFAULT_COOKIE_EXPIRATION_DAYS
) => {
  try {
    Cookies.set(key, JSON.stringify(jsonData), { expires });
  } catch (e) {
    console.error(e);
  }
};

export const getClientJSONCookie = (
  key: CookieKeys,
  defaultValue: string = '{}'
) => {
  try {
    return JSON.parse(Cookies.get(key) || defaultValue);
  } catch (e) {
    console.error(e);
  }

  return {};
};

export const setClientBooleanCookie = (
  key: CookieKeys,
  value: boolean,
  expires: number | Date = DEFAULT_COOKIE_EXPIRATION_DAYS
) => Cookies.set(key, value?.toString() || 'false', { expires });

export const getClientBooleanCookie = (key: CookieKeys): boolean =>
  Cookies.get(key) === 'true';

export function getServerCookie(
  key: CookieKeys,
  requestHeaders?: IncomingHttpHeaders
) {
  if (!requestHeaders?.cookie) return false;

  return cookie.parse(requestHeaders.cookie)?.[key];
}

export const clearClientCookie = (key: CookieKeys) => Cookies.remove(key);

export const setFinnSessionCookie = () => {
  const finnSessionId = Cookies.get(CookieKeys.X_FINN_SESSION_ID);
  if (finnSessionId) return;
  const newSessionId = getFinnSessionId();
  Cookies.set(CookieKeys.X_FINN_SESSION_ID, newSessionId);
};
